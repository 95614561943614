import { ChangeDetectorRef, Component, WritableSignal, signal } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { InstalmentSchedule, TransactionType } from 'src/app/service.portal.types';

@Component({
    selector: 'formly-field-instalment-list',
    templateUrl: './instalment.type.html',
    styleUrls: ['instalment.type.css'],
})
export class InstalmentScheduleTabs extends FieldType {
    currentTabIndex: BehaviorSubject<number> = new BehaviorSubject(0);
    displayedColumns: string[] = ['instalmentIndex', 'dueDate', 'dueAmountString'];

    private _selectedIndex: number = 0;
    private _loading: boolean = true;
    protected instalments: WritableSignal<InstalmentSchedule[] | undefined> = signal(undefined);

    get loading() {
        return this._loading;
    }

    get selectedIndex() {
        return this._selectedIndex;
    }

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.props.getCurrentTab = this.getActiveTab.bind(this);
        this.props.loading = this.setLoading.bind(this);

        this.field.formControl.valueChanges.subscribe((instalments: InstalmentSchedule[]) => {
            this.instalments.set(instalments);
        });
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): number {
        this.currentTabIndex.next(tabChangeEvent.index);
        return tabChangeEvent.index;
    }

    setLoading(value: boolean) {
        this._loading = value;
        this.cd.detectChanges();
    }

    getActiveTab(asObservable: boolean = true) {
        return asObservable ? this.currentTabIndex : this.currentTabIndex.value;
    }

    // When there is a new instalment, not a revision of any existing instalment
    // The instalment number (#) in the table should be left blank.
    protected getInstalmentIndex(instalment: InstalmentSchedule, index: number): number | string {
        const isAmdOrCn = [TransactionType.amendment, TransactionType.cancellation].includes(
            instalment.transactionType
        );
        const isNewInstalment = !instalment.revision || instalment.revision === 0;
        if (this.instalments().length === 1 && isNewInstalment && isAmdOrCn) return '';
        return index + 1;
    }
}
