<div *ngIf="showQuote">
    <div class="row justify-content-center" *ngIf="currency">
        <h4>Gross Premium (including taxes): {{currency}}{{amountDue}}</h4>
        <br />
    </div>
    <div class="row justify-content-center" *ngIf="submitting">
        <br />
        <br />
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Submitting...</span>
        </div>
    </div>
    <div *ngIf="!submitting">
        <div class="row justify-content-center">
            <div class="col-md-5">
                <sp-invoice-component (invoiceLoaded)="onInvoiceLoaded($event)"></sp-invoice-component>

                <!-- intermediaryHybrid & Direct User - show contact details of originator -->
                <div *ngIf="isIntermediaryHybrid && isDirect && !loadingInvoice" class="border border-dark p-2">
                    <span>There has been an intermediary assigned to your account.</span> <br />
                    <span>If required, please do not hesitate to contact: </span>
                    <div class="row justify-content-center mt-3">
                        <div class="col-2">
                            <img *ngIf="theme.logo" class="paLogo" [src]="themeLogo" [alt]="theme.name + ' Logo'" />
                        </div>
                        <div class="col-6 text-center">
                            {{ originator?.individual }} <br />
                            <a href="mailto:{{originatorUser?.emailAddress}}">{{originatorUser?.emailAddress}}</a><br />
                            <a href="tel:{{ originatorUser?.phoneNumber }}">{{ originatorUser?.phoneNumber }}</a>
                        </div>
                    </div>
                </div>
                <br />

                <!-- intermediaryHybrid & Originator User - show contact details of direct user -->
                <div *ngIf="isIntermediaryHybrid && !isDirect && !loadingInvoice" class="border border-dark p-2">
                    <span>This is a Hybrid account.</span> <br />
                    <span>If required, please do not hesitate to contact: </span>
                    <ng-container *ngTemplateOutlet="insuredContactDetails"> </ng-container>
                </div>

                <!-- insuredPolicyManager - show contact details of direct user -->
                <div
                    *ngIf="originator?.type === 'insuredPolicyManager' && !loadingInvoice"
                    class="border border-dark p-2"
                >
                    <span>Please see the Insured's contact details below: </span>
                    <ng-container *ngTemplateOutlet="insuredContactDetails"> </ng-container>
                </div>

                <!-- Quote invalid message for AMD/CN -->
                <div
                    *ngIf="isAmdOrCnTransaction && !isLatestRevision && !loadingInvoice"
                    class="pt-3 border border-dark"
                >
                    <p class="text-center">
                        <strong> This quote is no longer valid as the policy has been amended. </strong>
                    </p>
                    <p class="text-center">
                        <strong>
                            Please submit a new proposal by following the links in your latest policy email.
                        </strong>
                    </p>
                </div>

                <!-- Form for inputting date and additional details -->
                <formly-form
                    [model]="model"
                    [form]="acceptTermsForm"
                    [fields]="fields"
                    [options]="options"
                    *ngIf="!isInvoiceVoidedOrExpired && !cannotBind && isLatestRevision"
                >
                </formly-form>

                <!-- Originator user - Accept terms & Request hold cover -->
                <div class="justify-content-center btn-group w-100 mt-5" *ngIf="!loadingInvoice">
                    <button
                        type="button"
                        *ngIf="showHoldCover"
                        id="holdCoverButton"
                        class="btn btn-secondary col-md-4 col-11"
                        (click)="openHoldCover()"
                        data-testid="holdCover"
                        mat-button
                    >
                        Request to Hold Cover
                    </button>
                    <button
                        type="button"
                        *ngIf="canAcceptTerms()"
                        id="submitButton"
                        class="btn btn-primary col-md-4 col-11"
                        (click)="confirm()"
                        data-testid="acceptTerms"
                        mat-button
                        [disabled]="!acceptTermsForm.valid && !(acceptTermsForm.status === 'DISABLED')"
                    >
                        Accept Terms
                    </button>
                </div>

                <!-- Direct user - Payment options -->
                <div
                    *ngIf="showPaymentOptions && paymentOptionsConfigured"
                    class="justify-content-center payment-options"
                >
                    <div class="table-header">
                        <h4>Payment Options</h4>
                        <i
                            class="fas fa-question-circle text-primary"
                            matTooltip="To begin your policy at your proposed inception date, please select one of the following secured payment options."
                        ></i>
                    </div>
                    <button
                        *ngFor="let paymentOption of paymentOptions"
                        type="button"
                        class="link-list btn btn-primary col-md-8 offset-md-2"
                        (click)="paymentOption.handler()"
                        [disabled]="paymentOption.loading || paymentOptionsDisabled"
                        [attr.data-testid]="'payment-method-' + paymentOption.type"
                        mat-button
                    >
                        <p class="m-0" *ngIf="!paymentOption.loading">{{paymentOption.title}}</p>
                        <div class="spinner-border" *ngIf="paymentOption.loading"></div>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isInvoiceVoidedOrExpired">
        <div class="row justify-content-center warn-text">
            <p>Your quote has expired!</p>
            <br />
        </div>
        <div class="row justify-content-center">
            <button
                type="button"
                class="btn btn-primary"
                data-testid="newQuote"
                mat-button
                (click)="redirectToNewQuote()"
            >
                New Quote
            </button>
        </div>
    </div>
</div>

<ng-template #confirmDialog>
    <h5 mat-dialog-title>Please confirm:</h5>
    <mat-dialog-content>
        <div class="disclaimer-container">
            <div *ngIf="selectedPaymentOption !== 'bankTransfer'; else confirmBankDetails">{{confirmationMessage}}</div>
            <ng-template #confirmBankDetails>
                Please confirm that you have made a payment to:
                <br />
                <ng-container *ngTemplateOutlet="bankDetails"></ng-container>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="btn-group justify-content-center" role="group">
            <button type="button" class="btn btn-secondary" data-testid="cancel" mat-button mat-dialog-close>
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-primary"
                data-testid="confirmAccept"
                mat-button
                cdkFocusInitial
                (click)="submit()"
            >
                Accept
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #holdCoverDialog>
    <h5 mat-dialog-title>Request to Hold Cover</h5>
    <mat-dialog-content style="width: 500px">
        <div>
            <h6>Please confirm a proposed Hold Cover Expiry Date</h6>
            <i>* {{tooltip}}</i>
            <formly-form [form]="holdCoverForm" [model]="holdCoverModel" [fields]="holdCoverFields" [options]="options">
            </formly-form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="btn-group justify-content-center" role="group">
            <button
                type="button"
                class="btn btn-secondary"
                data-testid="cancel"
                mat-button
                mat-dialog-close
                [disabled]="submitting"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-primary"
                data-testid="confirmAccept"
                mat-button
                cdkFocusInitial
                [disabled]="holdCoverForm.invalid || submitting"
                (click)="confirmHoldCover()"
            >
                <i *ngIf="submitting" class="fal fa-spinner-third fa-spin"></i>
                Submit Request
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #confirmHolDCoverDialog>
    <h5 mat-dialog-title>Confirm Request:</h5>
    <mat-dialog-content>
        <div class="disclaimer-container">Are you sure? Once requested, the Underwriter will be notified.</div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="btn-group justify-content-center" role="group">
            <button type="button" class="btn btn-secondary" data-testid="cancel" mat-button mat-dialog-close>
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-primary"
                data-testid="confirmAccept"
                mat-button
                cdkFocusInitial
                (click)="submitHoldCover()"
            >
                Confirm
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #errorDialog>
    <mat-dialog-content>
        <div class="row text-center">
            <p>
                An error occurred when submitting your request. Please try again.
                <br />
                Please contact <a href="mailto:support@precision-autonomy.com">support&#64;precision-autonomy.com</a>
                for assistance.
            </p>
        </div>
    </mat-dialog-content>
</ng-template>

<div *ngIf="renewalInvalid">
    <div class="row justify-content-center">
        <h4>This renewal notice is no longer valid as the policy has been amended since it was generated.</h4>
    </div>
    <div class="row justify-content-center">
        <p>You should receive a new renewal notice for the amended policy within a few minutes.</p>
    </div>
</div>

<ng-template #bankTransferDialog>
    <mat-dialog-content>
        <div>
            <div class="row">To pay via bank transfer, please transfer the funds to the following account:</div>
            <div class="row justify-content-center">
                <ng-container *ngTemplateOutlet="bankDetails"></ng-container>
            </div>
            <div class="row">
                Once you have made the payment, please click the accept terms button to confirm the payment.
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="btn-group justify-content-center" role="group">
            <button type="button" class="btn btn-secondary" data-testid="cancel" mat-button mat-dialog-close>
                Close
            </button>
            <button
                type="button"
                class="btn btn-primary"
                data-testid="acceptTerms"
                (click)="confirm()"
                mat-button
                mat-dialog-close
            >
                Accept Terms
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #attvestDialog>
    <mat-dialog-content>
        <div class="row text-center">
            <p>
                To pay via months payments, click the accept terms button and a member of our team will contact you to
                organize the payments.
            </p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="btn-group justify-content-center" role="group">
            <button type="button" class="btn btn-secondary" data-testid="cancel" mat-button mat-dialog-close>
                Close
            </button>
            <button
                type="button"
                class="btn btn-primary"
                data-testid="acceptTerms"
                (click)="confirm()"
                mat-button
                mat-dialog-close
            >
                Accept Terms
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #bankDetails>
    <div>
        <b>Precision Autonomy Pty Ltd.</b>
        <table class="mat-table">
            <tr class="mat-row">
                <td class="mat-cell"><b>BSB: </b></td>
                <td class="mat-cell">182-222</td>
            </tr>
            <tr class="mat-row">
                <td class="mat-cell"><b>Account Number: </b></td>
                <td class="mat-cell">304520554</td>
            </tr>
        </table>
    </div>
</ng-template>

<ng-template #insuredContactDetails>
    <div class="row justify-content-center">
        <div class="col-6 text-center">
            {{ (insureContact?.givenName || '') + ' ' + (insureContact?.familyName || '') }}
            <br />
            <a href="mailto:{{insureContact?.emailAddress}}">{{insureContact?.emailAddress}}</a><br />
            <a href="tel:{{ insureContact?.phoneNumber }}">{{ insureContact?.phoneNumber }}</a>
        </div>
    </div>
</ng-template>
