<div *ngIf="premiumBreakdown && !cannotBind">
    <div class="invoice-container" *ngIf="!quoteLapsed">
        <div class="table-header">
            <h4 data-testid="details-title">{{ detailsName }} Details</h4>
            <a
                mat-stroked-button
                [href]="editQuoteUrl"
                target="_blank"
                *ngIf="!hasPaid && editQuoteUrl && !expiryDateExceeded"
                matTooltip="Edit Quote"
                matTooltipPosition="right"
                data-testid="editSubmission"
            >
                <i class="fal fa-pencil"></i> Edit Submission
            </a>
        </div>
        <table class="quote-policy-details">
            <tr>
                <td>Insured Name:</td>
                <td data-testid="insured-name">
                    {{ premiumBreakdown.insuredName }}
                </td>
            </tr>
            <tr *ngIf="hasPaid">
                <td>Policy Reference:</td>
                <td data-testid="policy-reference">{{ policyDetail.reference }}</td>
            </tr>
            <tr *ngIf="hasPaid">
                <td>Period of Insurance:</td>
                <td>
                    <pa-format-date [dateConfig]="policyDetail.renewalDate" data-testid="renewal-date"></pa-format-date>
                    -
                    <pa-format-date [dateConfig]="policyDetail.expiryDate" data-testid="expiry-date"></pa-format-date>
                </td>
            </tr>
            <tr *ngIf="hasPaid && amendEffectiveDateTitle">
                <td>{{ amendEffectiveDateTitle }}:</td>
                <td>
                    <pa-format-date [dateConfig]="policyDetail.amendedDate" data-testid="amended-date"></pa-format-date>
                </td>
            </tr>
        </table>
        <div *ngIf="!hasPaid">
            <table class="premium-details">
                <tr>
                    <td colspan="2" class="no-padding">
                        <hr />
                    </td>
                </tr>
                <tr>
                    <td>Base Premium:</td>
                    <td data-testid="basePremium">{{ premiumBreakdown.basePrem }}</td>
                </tr>
                <tr *ngIf="premiumBreakdown.simpleTaxTotal !== undefined">
                    <td>
                        Tax Total
                        <b> <i class="fas fa-question-circle text-primary" matTooltip="{{ taxTooltip }}"></i> </b>:
                    </td>
                    <td data-testid="totalTax">{{ premiumBreakdown.simpleTaxTotal }}</td>
                </tr>
                <tr *ngFor="let tax of premiumBreakdown.taxBreakdown; let i = index">
                    <td>{{ tax.taxName }}</td>
                    <td [attr.data-testid]="tax.taxName">{{ tax.amount }}</td>
                </tr>
                <ng-container *ngIf="!directInsured">
                    <tr>
                        <td>Gross:</td>
                        <td data-testid="grossAmount">{{ premiumBreakdown.gross }}</td>
                    </tr>
                    <tr
                        *ngIf="
                            premiumBreakdown.commissionRate ||
                            premiumBreakdown.commission ||
                            premiumBreakdown.commissionTaxes
                        "
                    >
                        <td colspan="2" class="no-padding">
                            <hr />
                        </td>
                    </tr>
                    <tr *ngIf="premiumBreakdown.commissionRate">
                        <td>Commission Rate:</td>
                        <td data-testid="commissionRate">{{ premiumBreakdown.commissionRate }}%</td>
                    </tr>
                    <tr *ngIf="premiumBreakdown.commission">
                        <td>Commission:</td>
                        <td data-testid="commission">{{ premiumBreakdown.commission }}</td>
                    </tr>
                    <tr *ngIf="premiumBreakdown.commissionTaxes">
                        <td>Commission Taxes:</td>
                        <td data-testid="commissionTaxes">{{ premiumBreakdown.commissionTaxes }}</td>
                    </tr>
                </ng-container>
                <tr *ngIf="premiumBreakdown.policyFee">
                    <td colspan="2" class="no-padding">
                        <hr />
                    </td>
                </tr>
                <tr *ngIf="premiumBreakdown.policyFee">
                    <td>Policy Fee:</td>
                    <td data-testid="policyFee">{{ premiumBreakdown.policyFee }}</td>
                </tr>
                <tr *ngIf="premiumBreakdown.policyFee">
                    <td>Policy Fee Taxes:</td>
                    <td data-testid="policyFeeTax">{{ premiumBreakdown.policyFeeTax }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="no-padding">
                        <hr />
                    </td>
                </tr>
                <tr>
                    <td data-testid="netPayableLabel">{{ netPayable }}</td>
                    <td data-testid="netPayable">{{ premiumBreakdown.netInsurer }}</td>
                </tr>
                <tr>
                    <td colspan="2" class="no-padding">
                        <hr />
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div *ngIf="quoteLapsed">
        <div class="row justify-content-center">
            <h4 data-testid="quoteLapsedMessage">{{ quoteLapsedMessage }}</h4>
        </div>
        <br />
        <div class="row justify-content-center">
            <a
                class="btn btn-primary col-md-3 col-11"
                mat-button
                data-testid="newQuoteButton"
                href="{{ editQuoteUrl }}"
            >
                New Quote
            </a>
        </div>
        <br />
    </div>
    <div class="invoice-container" *ngIf="(invoicePdf && showInvoice) || attachments.length">
        <h4>Documentation (Downloadable links):</h4>
        <div *ngIf="showWarning" class="warn-text" data-testid="docsWarning">
            Please wait for a few minutes while the policy documents are being generated.
        </div>
        <div>
            <div class="row">
                <ul class="text-left fa-ul">
                    <li *ngIf="invoicePdf && showInvoice">
                        <span class="fa-li"><i [ngClass]="listClass"></i></span
                        ><a [href]="invoicePdf" target="_blank" data-testid="invoicePdfLink">{{ invoicePdfName }}</a>
                    </li>
                    <li *ngFor="let attachment of attachments; let i = index">
                        <span class="fa-li"><i [ngClass]="listClass"></i></span
                        ><a [href]="attachment.url" target="_blank" attr.data-testid="attachmentLink-{{ i }}">
                            {{ attachment.fileName }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div *ngIf="cannotBind">
    <div class="row justify-content-center warn-text">
        <p data-testid="cannotBindMessage">
            We require additional information before this {{ detailsName }} can be bound.
        </p>
        <br />
    </div>
    <div class="row justify-content-center">
        <a type="button" class="btn btn-primary" [href]="editQuoteUrl" target="_blank">Provide Details</a>
    </div>
</div>
<div class="row justify-content-center" *ngIf="loadingInvoice">
    <br />
    <br />
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only"></span>
    </div>
</div>
